/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VvfVolontarioCorpoDto } from '../../models/vvf-volontario-corpo-dto';

export interface ApiOrganicoVolontarioListByCorpoGet$Params {
    /**
     * Id del corpo per cui caricare i volontari
     */
    corpoId: number;
}

export function apiOrganicoVolontarioListByCorpoGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiOrganicoVolontarioListByCorpoGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<Array<VvfVolontarioCorpoDto>>> {
    const rb = new RequestBuilder(rootUrl, apiOrganicoVolontarioListByCorpoGet.PATH, 'get');
    if (params) {
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<VvfVolontarioCorpoDto>>;
        }),
    );
}

apiOrganicoVolontarioListByCorpoGet.PATH = '/api/organico/volontario-list-by-corpo';
