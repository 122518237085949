/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiSessionsCountGet } from '../fn/session/api-sessions-count-get';
import { ApiSessionsCountGet$Params } from '../fn/session/api-sessions-count-get';
import { apiSessionsGet } from '../fn/session/api-sessions-get';
import { ApiSessionsGet$Params } from '../fn/session/api-sessions-get';
import { apiSessionsIdGet } from '../fn/session/api-sessions-id-get';
import { ApiSessionsIdGet$Params } from '../fn/session/api-sessions-id-get';
import { DefaultMultiRecordReturnDto } from '../models/default-multi-record-return-dto';
import { DefaultSingleRecordReturnDto } from '../models/default-single-record-return-dto';

@Injectable({ providedIn: 'root' })
export class SessionService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiSessionsGet()` */
    static readonly ApiSessionsGetPath = '/api/sessions';

    /**
     * Get all sessions (filter, order, skip, take).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSessionsGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSessionsGet$Response(
        params?: ApiSessionsGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<DefaultMultiRecordReturnDto>> {
        return apiSessionsGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get all sessions (filter, order, skip, take).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSessionsGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSessionsGet(params?: ApiSessionsGet$Params, context?: HttpContext): Observable<DefaultMultiRecordReturnDto> {
        return this.apiSessionsGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultMultiRecordReturnDto>): DefaultMultiRecordReturnDto => r.body),
        );
    }

    /** Path part for operation `apiSessionsIdGet()` */
    static readonly ApiSessionsIdGetPath = '/api/sessions/:id';

    /**
     * Get a session by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSessionsIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSessionsIdGet$Response(
        params: ApiSessionsIdGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiSessionsIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get a session by id.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSessionsIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSessionsIdGet(params: ApiSessionsIdGet$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiSessionsIdGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body),
        );
    }

    /** Path part for operation `apiSessionsCountGet()` */
    static readonly ApiSessionsCountGetPath = '/api/sessions/count';

    /**
     * Get the number of sessions.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSessionsCountGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSessionsCountGet$Response(
        params?: ApiSessionsCountGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
        return apiSessionsCountGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Get the number of sessions.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSessionsCountGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSessionsCountGet(params?: ApiSessionsCountGet$Params, context?: HttpContext): Observable<DefaultSingleRecordReturnDto> {
        return this.apiSessionsCountGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<DefaultSingleRecordReturnDto>): DefaultSingleRecordReturnDto => r.body),
        );
    }
}
