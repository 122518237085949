import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { NgxAuthService } from 'ngx-auth-utils';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '@core/http-gen/services/auth.service';

import { GradoTokenDto } from '@core/http-gen/models';
import { confirmDialogConfig } from '@core/utils/dialog-config';
import { UtenteProfiloDto } from '@core/http-gen/models/utente-profilo-dto';
import { LocalStorageConstants } from '@shared/constants/local-storage.constants';

import { GradoItem } from './header.models';
import { HeaderService } from './header.service';
import { Permesso, PermessoValore } from '@shared/types/auth.models';
import { PermissionCheckerService } from '@shared/permissions/permission-checker.service';
import { LocalStorageService } from '@core/services/storage.service';
import { THEME_ICONS } from '@shared/faIcons';
import { DispositivoMobileService } from '@core/http-gen/services/dispositivo-mobile.service';

import { MenuItem } from 'primeng/api';
import { TipoUtente } from '@modules/gestione-utenti/nuovo-utente-esterno/nuovo-utente-esterno.models';

import { getUserType } from '@core/utils/enum-to-value.utils';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    readonly icons = THEME_ICONS;
    public gradoList: Array<GradoItem> = [];
    public user: UtenteProfiloDto | undefined = undefined;
    public userName: string | undefined = undefined;
    public userType: string | undefined = undefined;
    public canSeeGestioneRuoli = false;
    public canSeeGestioneUtenti = false;

    items: MenuItemWithPermission[] = [
        {
            id: 'NumeriUtili',
            requiredPermission: Permesso.RubricaTelefonica,
            visible: false,
            label: 'Contatti',
            routerLink: ['/contatti'],
        },
        {
            id: 'Scadenze',
            label: 'Scadenze',
            routerLink: ['/scadenze-lista'],
            visible: true,
        },
        {
            id: 'Eventi',
            label: 'Eventi',
            requiredPermission: Permesso.StoricoSelettive,
            visible: true,
            items: [
                {
                    id: 'Lista Selettive',
                    label: 'Lista Selettive',
                    routerLink: ['/alertcalls'],
                    visible: true,
                },
                // {
                //     id: 'Crea Selettiva',
                //     label: 'Crea Selettiva',
                //     routerLink: ['/alertcalls/create'],
                //     visible: false,
                // },
            ],
        },
        {
            id: 'Display',
            label: 'Display',
            routerLink: ['/'],
            requiredPermission: Permesso.MonitorSelettive,
            visible: false,
            items: [
                {
                    id: 'displaySelettiveFull',
                    label: 'Completo (Full HD)',
                    routerLink: ['/display-selettive-full'],
                    requiredPermission: Permesso.MonitorSelettive,
                    visible: false,
                },
                {
                    id: 'displaySelettive',
                    icon: 'pi pi-fw pi-bell',
                    label: 'Presenti ',
                    routerLink: ['/display-selettive'],
                    requiredPermission: Permesso.MonitorSelettive,
                    visible: false,
                },
                {
                    id: 'displaySelettive',
                    label: 'Dettaglio evento',
                    routerLink: ['/display-selettive?eventDetailsBox=true&notificationsCountBox=true'],
                    requiredPermission: Permesso.MonitorSelettive,
                    visible: false,
                },
                {
                    id: 'MonitorCentrale',
                    label: 'Monitor Centrale',
                    routerLink: ['/monitor-centrale'],
                    requiredPermission: Permesso.MonitorCentrale,
                    visible: false,
                },
            ],
        },
        {
            id: 'In development',
            label: 'In development',
            routerLink: ['/'],
            requiredRole: TipoUtente.Administrator,
            visible: false,
            items: [
                {
                    id: 'Mappa',
                    label: 'Mappa (dev)',
                    routerLink: ['/mappa'],
                    requiredPermission: Permesso.Mappa,
                    requiredRole: TipoUtente.Administrator,
                    visible: true,
                },
                {
                    id: 'Idranti',
                    label: 'Gestione Idranti (dev)',
                    routerLink: ['/idranti'],
                    requiredPermission: Permesso.Mappa,
                    requiredRole: TipoUtente.Administrator,
                    visible: true,
                },
                {
                    id: 'Notifiche',
                    label: 'Invio Notifiche  (dev)',
                    routerLink: ['/invio-notifiche'],
                    requiredPermission: Permesso.Notifiche,
                    requiredRole: TipoUtente.Administrator,
                    visible: true,
                },
            ],
        },
        {
            id: 'Moduli_aggiuntivi',
            label: 'Moduli aggiuntivi',
            routerLink: ['/'],
            visible: true,
            items: [
                {
                    id: 'ModuliAggiuntiviConfig',
                    label: 'Configurazione moduli aggiuntivi',
                    routerLink: ['/modulo-aggiuntivo/gestione-moduli'],
                    requiredPermission: undefined,
                    requiredRole: TipoUtente.Administrator,
                    visible: true,
                },
                {
                    id: 'CaricamentiMultimediali',
                    label: 'Caricamenti multimediali',
                    routerLink: ['/modulo-aggiuntivo/caricamenti-multimediali'],
                    requiredPermission: Permesso.ConfigurazioneCaricamentoMultimediale,
                    requiredRole: TipoUtente.Administrator,
                    visible: true,
                },
            ],
        },
        {
            id: 'Admin Panel',
            label: 'Pannello Admin',
            routerLink: ['/admin-panel'],
            requiredRole: TipoUtente.Administrator,
            visible: true,
        },
    ];

    constructor(
        private authenticationService: NgxAuthService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private _ngxAuthService: NgxAuthService,
        public headerService: HeaderService,
        public dispositivoMobileService: DispositivoMobileService,
        public permissionChecker: PermissionCheckerService,
        public localStorage: LocalStorageService,
        public authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // questo nasconde/mostra la voce nel menu in base a se ha il permesso di lettura o meno (e se è un admin)

        this._ngxAuthService.state.pipe(untilDestroyed(this)).subscribe((user: UtenteProfiloDto | null) => {
            this.items.forEach((menuItem) => {
                let autorizedByRole: boolean = false;
                // Check type of users
                if (menuItem.requiredRole && user?.tokenData?.tipoUtente) {
                    menuItem.visible = menuItem.requiredRole == user.tokenData.tipoUtente;
                    if (menuItem.visible) {
                        autorizedByRole = true;
                    }
                }

                if (!autorizedByRole && menuItem.requiredPermission && user?.tokenData?.permessoList) {
                    menuItem.visible = this.permissionChecker.hasThePermission(
                        menuItem.requiredPermission,
                        PermessoValore.Lettura,
                        user.tokenData.permessoList,
                    );
                }
                if (menuItem.items) {
                    menuItem.items.forEach((subItem) => {
                        let autorizedByRoleSubItem: boolean = false;
                        if (subItem.requiredRole && user?.tokenData?.tipoUtente) {
                            subItem.visible = subItem.requiredRole == user.tokenData.tipoUtente;
                            if (subItem.visible) {
                                autorizedByRoleSubItem = true;
                            }
                        }

                        if (!autorizedByRoleSubItem && subItem.requiredPermission && user?.tokenData?.permessoList) {
                            subItem.visible = this.permissionChecker.hasThePermission(
                                subItem.requiredPermission,
                                PermessoValore.Lettura,
                                user.tokenData.permessoList,
                            );
                        }
                    });

                    // Se nessun figlio è visibile nascondo anche il padre
                    const isVisible: boolean = menuItem.items.some(
                        (menuItemWithPermission: MenuItemWithPermission) => menuItemWithPermission.visible,
                    );
                    if (!isVisible) {
                        menuItem.visible = false;
                    }
                }
            });

            this.afterLogin();
            this.cdr.markForCheck(); // Manually trigger change detection after data updates
        });
    }

    private afterLogin(): void {
        // Carico il profilo dell'utente, e se sono presenti più gradi li imposto nel servizio che si occupa della loro gestione
        const utenteProfiloDto: UtenteProfiloDto = this._ngxAuthService.snapshot.user;

        if ((utenteProfiloDto?.tokenData?.gradoList?.length ?? 0) !== 0) {
            const gradoTokenList: Array<GradoTokenDto> | undefined = utenteProfiloDto.tokenData?.gradoList;
            if (gradoTokenList != null) {
                this.headerService.setGradoList(gradoTokenList);
            }
        }

        // Sottoscrivo la lista dei gradi, in modo che se cambia (es. logout\login) si avranno le informazioni aggiornate nella DropdownBox
        this.headerService.gradoList$.subscribe((gradoList: Array<GradoItem>) => {
            this.gradoList = gradoList;
            this.cdr.markForCheck(); // Manually trigger change detection after data updates
        });

        this.canSeeGestioneRuoli = this.permissionChecker.hasThePermission(
            Permesso.GestioneRuoli,
            PermessoValore.Lettura,
            this._ngxAuthService.snapshot.user?.tokenData.permessoList,
        );

        this.canSeeGestioneUtenti = this.permissionChecker.hasThePermission(
            Permesso.GestioneUtenti,
            PermessoValore.Lettura,
            this._ngxAuthService.snapshot.user?.tokenData.permessoList,
        );

        this.user = utenteProfiloDto;

        // Set the user name
        // Use name or surname if present, otherwise use the username
        if (this.user.nome && this.user.cognome) {
            this.userName = this.user.nome + ' ' + this.user.cognome;
        } else if (this.user.nome) {
            this.userName = this.user.nome;
        } else if (this.user.cognome) {
            this.userName = this.user.cognome;
        } else {
            this.userName = this.user.nomeUtente;
        }

        // Set the user type

        if (this.user.tokenData?.tipoUtente != null) {
            this.userType = getUserType(this.user.tokenData.tipoUtente);
        }
    }

    logout(): void {
        this.confirmationService.confirm({
            message: 'Vuoi disconnetterti dal sito?',
            header: 'Logout',
            acceptLabel: 'Conferma',
            rejectLabel: 'Annulla',
            icon: 'pi pi-exclamation-triangle',
            ...confirmDialogConfig,
            accept: () => {
                // Remove the session from the server
                this.authService.apiAuthLogoutDelete().subscribe();

                this.authenticationService.logout();
                // Pulisco il contenuto del localStorage e i gradi contenuti nel servizio
                localStorage.clear();
                this.headerService.cleanGradoList();
                this.router.navigate(['/auth/login']);
            },
        });
    }

    /** Metodo legato al cambio di grado nella pagina */
    public changeGrado(grado: GradoItem): void {
        // Imposto nel localstorage gli header che verranno utilizzati
        localStorage.setItem(LocalStorageConstants.idGradoHeader, grado.idGrado.toString());
        localStorage.setItem(LocalStorageConstants.tipoEntitaHeader, (+grado.tipoEntita).toString());
        localStorage.setItem(LocalStorageConstants.idEntitaHeader, grado.idEntita.toString());

        // Modifico il grado selezionato nella lista
        this.gradoList.forEach((gradoItem: GradoItem) => {
            gradoItem.selected = gradoItem.idGrado === grado.idGrado;
        });

        // Dopo aver cambiato il grado, ricarico la pagina per aggiornare i dati
        window.location.reload();
    }

    /** Check if is in this page */
    public isCurrentPage(page: string): boolean {
        return this.router.url.includes(page);
    }
}

interface MenuItemWithPermission extends MenuItem {
    requiredPermission?: Permesso;
    requiredRole?: TipoUtente;
    items?: MenuItemWithPermission[];
}
