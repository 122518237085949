/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiLogMobileAppPost } from '../fn/log/api-log-mobile-app-post';
import { ApiLogMobileAppPost$Params } from '../fn/log/api-log-mobile-app-post';
import { ReturnLogMobileAppDto } from '../models/return-log-mobile-app-dto';

@Injectable({ providedIn: 'root' })
export class LogService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiLogMobileAppPost()` */
    static readonly ApiLogMobileAppPostPath = '/api/log/mobile-app';

    /**
     * Add logs from mobile app to the database (no authentication required).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiLogMobileAppPost()` instead.
     *
     * This method sends `Log to add` and handles request body of type `Log to add`.
     */
    apiLogMobileAppPost$Response(
        params: ApiLogMobileAppPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<ReturnLogMobileAppDto>> {
        return apiLogMobileAppPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Add logs from mobile app to the database (no authentication required).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiLogMobileAppPost$Response()` instead.
     *
     * This method sends `Log to add` and handles request body of type `Log to add`.
     */
    apiLogMobileAppPost(params: ApiLogMobileAppPost$Params, context?: HttpContext): Observable<ReturnLogMobileAppDto> {
        return this.apiLogMobileAppPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<ReturnLogMobileAppDto>): ReturnLogMobileAppDto => r.body),
        );
    }
}
