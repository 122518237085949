/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAerotalkAlertcallPost } from '../fn/selettiva/api-aerotalk-alertcall-post';
import { ApiAerotalkAlertcallPost$Params } from '../fn/selettiva/api-aerotalk-alertcall-post';
import { apiSelettivaSelettivaAudioBySelettivaIdGet } from '../fn/selettiva/api-selettiva-selettiva-audio-by-selettiva-id-get';
import { ApiSelettivaSelettivaAudioBySelettivaIdGet$Params } from '../fn/selettiva/api-selettiva-selettiva-audio-by-selettiva-id-get';
import { apiSelettivaSelettivaByIdGet } from '../fn/selettiva/api-selettiva-selettiva-by-id-get';
import { ApiSelettivaSelettivaByIdGet$Params } from '../fn/selettiva/api-selettiva-selettiva-by-id-get';
import { apiSelettivaSelettivaCentralePost } from '../fn/selettiva/api-selettiva-selettiva-centrale-post';
import { ApiSelettivaSelettivaCentralePost$Params } from '../fn/selettiva/api-selettiva-selettiva-centrale-post';
import { apiSelettivaSelettivaCountCentraleGet } from '../fn/selettiva/api-selettiva-selettiva-count-centrale-get';
import { ApiSelettivaSelettivaCountCentraleGet$Params } from '../fn/selettiva/api-selettiva-selettiva-count-centrale-get';
import { apiSelettivaSelettivaCountPartecipantiGet } from '../fn/selettiva/api-selettiva-selettiva-count-partecipanti-get';
import { ApiSelettivaSelettivaCountPartecipantiGet$Params } from '../fn/selettiva/api-selettiva-selettiva-count-partecipanti-get';
import { apiSelettivaSelettivaHasNoAudioGet } from '../fn/selettiva/api-selettiva-selettiva-has-no-audio-get';
import { ApiSelettivaSelettivaHasNoAudioGet$Params } from '../fn/selettiva/api-selettiva-selettiva-has-no-audio-get';
import { apiSelettivaSelettivaHasPulsantiRispostaGet } from '../fn/selettiva/api-selettiva-selettiva-has-pulsanti-risposta-get';
import { ApiSelettivaSelettivaHasPulsantiRispostaGet$Params } from '../fn/selettiva/api-selettiva-selettiva-has-pulsanti-risposta-get';
import { apiSelettivaSelettivaLastIdGet } from '../fn/selettiva/api-selettiva-selettiva-last-id-get';
import { ApiSelettivaSelettivaLastIdGet$Params } from '../fn/selettiva/api-selettiva-selettiva-last-id-get';
import { apiSelettivaSelettivaListGet } from '../fn/selettiva/api-selettiva-selettiva-list-get';
import { ApiSelettivaSelettivaListGet$Params } from '../fn/selettiva/api-selettiva-selettiva-list-get';
import { apiSelettivaSelettivaUpdatePartecipazionePost } from '../fn/selettiva/api-selettiva-selettiva-update-partecipazione-post';
import { ApiSelettivaSelettivaUpdatePartecipazionePost$Params } from '../fn/selettiva/api-selettiva-selettiva-update-partecipazione-post';
import { apiSelettivaSelettivaVolontarioListGet } from '../fn/selettiva/api-selettiva-selettiva-volontario-list-get';
import { ApiSelettivaSelettivaVolontarioListGet$Params } from '../fn/selettiva/api-selettiva-selettiva-volontario-list-get';
import { apiSelettivaSetNoAudioLastSelettivaPost } from '../fn/selettiva/api-selettiva-set-no-audio-last-selettiva-post';
import { ApiSelettivaSetNoAudioLastSelettivaPost$Params } from '../fn/selettiva/api-selettiva-set-no-audio-last-selettiva-post';
import { apiSelettivaTelegramPost } from '../fn/selettiva/api-selettiva-telegram-post';
import { ApiSelettivaTelegramPost$Params } from '../fn/selettiva/api-selettiva-telegram-post';
import { apiSelettivaUploadAudioLastSelettivaPost } from '../fn/selettiva/api-selettiva-upload-audio-last-selettiva-post';
import { ApiSelettivaUploadAudioLastSelettivaPost$Params } from '../fn/selettiva/api-selettiva-upload-audio-last-selettiva-post';
import { ReturnSelettivaDto } from '../models/return-selettiva-dto';
import { SelettivaCheckCentraleDto } from '../models/selettiva-check-centrale-dto';
import { SelettivaCountPartecipanteDto } from '../models/selettiva-count-partecipante-dto';
import { SelettivaDto } from '../models/selettiva-dto';
import { SelettivaListItemDto } from '../models/selettiva-list-item-dto';
import { SelettivaVolontarioDto } from '../models/selettiva-volontario-dto';

@Injectable({ providedIn: 'root' })
export class SelettivaService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiSelettivaTelegramPost()` */
    static readonly ApiSelettivaTelegramPostPath = '/api/selettiva/telegram';

    /**
     * Aggiunge una nuova selettiva da Telegram.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaTelegramPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiSelettivaTelegramPost$Response(
        params: ApiSelettivaTelegramPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<ReturnSelettivaDto>> {
        return apiSelettivaTelegramPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiunge una nuova selettiva da Telegram.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaTelegramPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiSelettivaTelegramPost(params: ApiSelettivaTelegramPost$Params, context?: HttpContext): Observable<ReturnSelettivaDto> {
        return this.apiSelettivaTelegramPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<ReturnSelettivaDto>): ReturnSelettivaDto => r.body),
        );
    }

    /** Path part for operation `apiAerotalkAlertcallPost()` */
    static readonly ApiAerotalkAlertcallPostPath = '/api/aerotalk/alertcall';

    /**
     * Aggiunge una nuova selettiva dalla centrale VVF TN.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiAerotalkAlertcallPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAerotalkAlertcallPost$Response(
        params: ApiAerotalkAlertcallPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<ReturnSelettivaDto>> {
        return apiAerotalkAlertcallPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiunge una nuova selettiva dalla centrale VVF TN.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiAerotalkAlertcallPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiAerotalkAlertcallPost(params: ApiAerotalkAlertcallPost$Params, context?: HttpContext): Observable<ReturnSelettivaDto> {
        return this.apiAerotalkAlertcallPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<ReturnSelettivaDto>): ReturnSelettivaDto => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaCentralePost()` */
    static readonly ApiSelettivaSelettivaCentralePostPath = '/api/selettiva/selettiva-centrale';

    /**
     * Riceve una selettiva da centrale 115 (DEPRECATED).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaCentralePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiSelettivaSelettivaCentralePost$Response(
        params: ApiSelettivaSelettivaCentralePost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<number>> {
        return apiSelettivaSelettivaCentralePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Riceve una selettiva da centrale 115 (DEPRECATED).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaCentralePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiSelettivaSelettivaCentralePost(params: ApiSelettivaSelettivaCentralePost$Params, context?: HttpContext): Observable<number> {
        return this.apiSelettivaSelettivaCentralePost$Response(params, context).pipe(
            map((r: StrictHttpResponse<number>): number => r.body),
        );
    }

    /** Path part for operation `apiSelettivaUploadAudioLastSelettivaPost()` */
    static readonly ApiSelettivaUploadAudioLastSelettivaPostPath = '/api/selettiva/upload-audio-last-selettiva';

    /**
     * Carica un file audio per l'ultima selettiva ricevuta dal corpo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaUploadAudioLastSelettivaPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaUploadAudioLastSelettivaPost$Response(
        params: ApiSelettivaUploadAudioLastSelettivaPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiSelettivaUploadAudioLastSelettivaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica un file audio per l'ultima selettiva ricevuta dal corpo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaUploadAudioLastSelettivaPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaUploadAudioLastSelettivaPost(
        params: ApiSelettivaUploadAudioLastSelettivaPost$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiSelettivaUploadAudioLastSelettivaPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSetNoAudioLastSelettivaPost()` */
    static readonly ApiSelettivaSetNoAudioLastSelettivaPostPath = '/api/selettiva/set-no-audio-last-selettiva';

    /**
     * Imposta il flag `noAudio` sull'ultima selettiva del corpo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSetNoAudioLastSelettivaPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSetNoAudioLastSelettivaPost$Response(
        params?: ApiSelettivaSetNoAudioLastSelettivaPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiSelettivaSetNoAudioLastSelettivaPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Imposta il flag `noAudio` sull'ultima selettiva del corpo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSetNoAudioLastSelettivaPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSetNoAudioLastSelettivaPost(
        params?: ApiSelettivaSetNoAudioLastSelettivaPost$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiSelettivaSetNoAudioLastSelettivaPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaHasNoAudioGet()` */
    static readonly ApiSelettivaSelettivaHasNoAudioGetPath = '/api/selettiva/selettiva-has-no-audio';

    /**
     * Carica il flag che indica se per una selettiva non è presente il file audio.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaHasNoAudioGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaHasNoAudioGet$Response(
        params: ApiSelettivaSelettivaHasNoAudioGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiSelettivaSelettivaHasNoAudioGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica il flag che indica se per una selettiva non è presente il file audio.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaHasNoAudioGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaHasNoAudioGet(params: ApiSelettivaSelettivaHasNoAudioGet$Params, context?: HttpContext): Observable<boolean> {
        return this.apiSelettivaSelettivaHasNoAudioGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaListGet()` */
    static readonly ApiSelettivaSelettivaListGetPath = '/api/selettiva/selettiva-list';

    /**
     * Carica la lista delle selettive visibili da parte dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaListGet$Response(
        params?: ApiSelettivaSelettivaListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<SelettivaListItemDto>>> {
        return apiSelettivaSelettivaListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista delle selettive visibili da parte dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaListGet(
        params?: ApiSelettivaSelettivaListGet$Params,
        context?: HttpContext,
    ): Observable<Array<SelettivaListItemDto>> {
        return this.apiSelettivaSelettivaListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<SelettivaListItemDto>>): Array<SelettivaListItemDto> => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaLastIdGet()` */
    static readonly ApiSelettivaSelettivaLastIdGetPath = '/api/selettiva/selettiva-last-id';

    /**
     * Carica l'ultima selettiva visibile da parte dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaLastIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaLastIdGet$Response(
        params?: ApiSelettivaSelettivaLastIdGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<number>> {
        return apiSelettivaSelettivaLastIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica l'ultima selettiva visibile da parte dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaLastIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaLastIdGet(params?: ApiSelettivaSelettivaLastIdGet$Params, context?: HttpContext): Observable<number> {
        return this.apiSelettivaSelettivaLastIdGet$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
    }

    /** Path part for operation `apiSelettivaSelettivaByIdGet()` */
    static readonly ApiSelettivaSelettivaByIdGetPath = '/api/selettiva/selettiva-by-id';

    /**
     * Carica i dati di una selettiva e la relativa partecipazione da parte di un utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaByIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaByIdGet$Response(
        params: ApiSelettivaSelettivaByIdGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<SelettivaDto>> {
        return apiSelettivaSelettivaByIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica i dati di una selettiva e la relativa partecipazione da parte di un utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaByIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaByIdGet(params: ApiSelettivaSelettivaByIdGet$Params, context?: HttpContext): Observable<SelettivaDto> {
        return this.apiSelettivaSelettivaByIdGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<SelettivaDto>): SelettivaDto => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaAudioBySelettivaIdGet()` */
    static readonly ApiSelettivaSelettivaAudioBySelettivaIdGetPath = '/api/selettiva/selettiva-audio-by-selettiva-id';

    /**
     * Carica l'eventuale file audio della la selettiva.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaAudioBySelettivaIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaAudioBySelettivaIdGet$Response(
        params: ApiSelettivaSelettivaAudioBySelettivaIdGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<{}>> {
        return apiSelettivaSelettivaAudioBySelettivaIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica l'eventuale file audio della la selettiva.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaAudioBySelettivaIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaAudioBySelettivaIdGet(
        params: ApiSelettivaSelettivaAudioBySelettivaIdGet$Params,
        context?: HttpContext,
    ): Observable<{}> {
        return this.apiSelettivaSelettivaAudioBySelettivaIdGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<{}>): {} => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaUpdatePartecipazionePost()` */
    static readonly ApiSelettivaSelettivaUpdatePartecipazionePostPath = '/api/selettiva/selettiva-update-partecipazione';

    /**
     * Aggiunge la partecipazione di un utente alla selettiva indicata.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaUpdatePartecipazionePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiSelettivaSelettivaUpdatePartecipazionePost$Response(
        params: ApiSelettivaSelettivaUpdatePartecipazionePost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiSelettivaSelettivaUpdatePartecipazionePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiunge la partecipazione di un utente alla selettiva indicata.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaUpdatePartecipazionePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiSelettivaSelettivaUpdatePartecipazionePost(
        params: ApiSelettivaSelettivaUpdatePartecipazionePost$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiSelettivaSelettivaUpdatePartecipazionePost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaVolontarioListGet()` */
    static readonly ApiSelettivaSelettivaVolontarioListGetPath = '/api/selettiva/selettiva-volontario-list';

    /**
     * Carica la lista dei partecipanti ad una selettiva.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaVolontarioListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaVolontarioListGet$Response(
        params: ApiSelettivaSelettivaVolontarioListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<SelettivaVolontarioDto>>> {
        return apiSelettivaSelettivaVolontarioListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei partecipanti ad una selettiva.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaVolontarioListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaVolontarioListGet(
        params: ApiSelettivaSelettivaVolontarioListGet$Params,
        context?: HttpContext,
    ): Observable<Array<SelettivaVolontarioDto>> {
        return this.apiSelettivaSelettivaVolontarioListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<SelettivaVolontarioDto>>): Array<SelettivaVolontarioDto> => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaCountPartecipantiGet()` */
    static readonly ApiSelettivaSelettivaCountPartecipantiGetPath = '/api/selettiva/selettiva-count-partecipanti';

    /**
     * Carica il numero dei partecipanti ad una selettiva.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaCountPartecipantiGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaCountPartecipantiGet$Response(
        params: ApiSelettivaSelettivaCountPartecipantiGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<SelettivaCountPartecipanteDto>> {
        return apiSelettivaSelettivaCountPartecipantiGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica il numero dei partecipanti ad una selettiva.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaCountPartecipantiGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaCountPartecipantiGet(
        params: ApiSelettivaSelettivaCountPartecipantiGet$Params,
        context?: HttpContext,
    ): Observable<SelettivaCountPartecipanteDto> {
        return this.apiSelettivaSelettivaCountPartecipantiGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<SelettivaCountPartecipanteDto>): SelettivaCountPartecipanteDto => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaHasPulsantiRispostaGet()` */
    static readonly ApiSelettivaSelettivaHasPulsantiRispostaGetPath = '/api/selettiva/selettiva-has-pulsanti-risposta';

    /**
     * Verifica se l'utente ha la visibilità dei pulsanti di risposta, utilizzato nel caso la selettiva sia di un corpo esterno.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaHasPulsantiRispostaGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaHasPulsantiRispostaGet$Response(
        params: ApiSelettivaSelettivaHasPulsantiRispostaGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiSelettivaSelettivaHasPulsantiRispostaGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Verifica se l'utente ha la visibilità dei pulsanti di risposta, utilizzato nel caso la selettiva sia di un corpo esterno.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaHasPulsantiRispostaGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaHasPulsantiRispostaGet(
        params: ApiSelettivaSelettivaHasPulsantiRispostaGet$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiSelettivaSelettivaHasPulsantiRispostaGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiSelettivaSelettivaCountCentraleGet()` */
    static readonly ApiSelettivaSelettivaCountCentraleGetPath = '/api/selettiva/selettiva-count-centrale';

    /**
     * Conta il numero di push notification inviate\ricevute e il numero di partecipanti alla selettiva.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiSelettivaSelettivaCountCentraleGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaCountCentraleGet$Response(
        params: ApiSelettivaSelettivaCountCentraleGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<SelettivaCheckCentraleDto>> {
        return apiSelettivaSelettivaCountCentraleGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Conta il numero di push notification inviate\ricevute e il numero di partecipanti alla selettiva.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiSelettivaSelettivaCountCentraleGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiSelettivaSelettivaCountCentraleGet(
        params: ApiSelettivaSelettivaCountCentraleGet$Params,
        context?: HttpContext,
    ): Observable<SelettivaCheckCentraleDto> {
        return this.apiSelettivaSelettivaCountCentraleGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<SelettivaCheckCentraleDto>): SelettivaCheckCentraleDto => r.body),
        );
    }
}
