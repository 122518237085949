/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiCaricamentoMultimedialeUploadMediaForEventPost$Params {
    /**
     * id dell'evento a cui collegare il file
     */
    eventoId: string;
}

export function apiCaricamentoMultimedialeUploadMediaForEventPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiCaricamentoMultimedialeUploadMediaForEventPost$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(rootUrl, apiCaricamentoMultimedialeUploadMediaForEventPost.PATH, 'post');
    if (params) {
        rb.query('eventoId', params.eventoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<string>;
        }),
    );
}

apiCaricamentoMultimedialeUploadMediaForEventPost.PATH = '/api/caricamento-multimediale/upload-media-for-event';
