/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { IdranteListByPosizioneRequestDto } from '../../models/idrante-list-by-posizione-request-dto';
import { MarcatoreDto } from '../../models/marcatore-dto';

export interface ApiMarcatoreIdranteListByPosizionePost$Params {
    /**
     * Oggetto contenente la posizione dei quattro angoli che delimitano il rettangolo
     */
    body: IdranteListByPosizioneRequestDto;
}

export function apiMarcatoreIdranteListByPosizionePost(
    http: HttpClient,
    rootUrl: string,
    params: ApiMarcatoreIdranteListByPosizionePost$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<Array<MarcatoreDto>>> {
    const rb = new RequestBuilder(rootUrl, apiMarcatoreIdranteListByPosizionePost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<MarcatoreDto>>;
        }),
    );
}

apiMarcatoreIdranteListByPosizionePost.PATH = '/api/marcatore/idrante-list-by-posizione';
