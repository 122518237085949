/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiRecapitoRecapitoCorpoListGet } from '../fn/recapito/api-recapito-recapito-corpo-list-get';
import { ApiRecapitoRecapitoCorpoListGet$Params } from '../fn/recapito/api-recapito-recapito-corpo-list-get';
import { apiRecapitoRecapitoVigileListGet } from '../fn/recapito/api-recapito-recapito-vigile-list-get';
import { ApiRecapitoRecapitoVigileListGet$Params } from '../fn/recapito/api-recapito-recapito-vigile-list-get';
import { RecapitoDto } from '../models/recapito-dto';
import { RecapitoVigileDto } from '../models/recapito-vigile-dto';

@Injectable({ providedIn: 'root' })
export class RecapitoService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiRecapitoRecapitoVigileListGet()` */
    static readonly ApiRecapitoRecapitoVigileListGetPath = '/api/recapito/recapito-vigile-list';

    /**
     * Carica tutti i recapiti di un vigile data la sua matricola.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiRecapitoRecapitoVigileListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiRecapitoRecapitoVigileListGet$Response(
        params: ApiRecapitoRecapitoVigileListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<RecapitoVigileDto>>> {
        return apiRecapitoRecapitoVigileListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica tutti i recapiti di un vigile data la sua matricola.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiRecapitoRecapitoVigileListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiRecapitoRecapitoVigileListGet(
        params: ApiRecapitoRecapitoVigileListGet$Params,
        context?: HttpContext,
    ): Observable<Array<RecapitoVigileDto>> {
        return this.apiRecapitoRecapitoVigileListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<RecapitoVigileDto>>): Array<RecapitoVigileDto> => r.body),
        );
    }

    /** Path part for operation `apiRecapitoRecapitoCorpoListGet()` */
    static readonly ApiRecapitoRecapitoCorpoListGetPath = '/api/recapito/recapito-corpo-list';

    /**
     * Carica la lista dei vigili che hanno almeno un recapito all'interno del corpo, se è specificato il recpito `cellulare-principale (9)` allora carica anche quello.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiRecapitoRecapitoCorpoListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiRecapitoRecapitoCorpoListGet$Response(
        params?: ApiRecapitoRecapitoCorpoListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<RecapitoDto>>> {
        return apiRecapitoRecapitoCorpoListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei vigili che hanno almeno un recapito all'interno del corpo, se è specificato il recpito `cellulare-principale (9)` allora carica anche quello.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiRecapitoRecapitoCorpoListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiRecapitoRecapitoCorpoListGet(
        params?: ApiRecapitoRecapitoCorpoListGet$Params,
        context?: HttpContext,
    ): Observable<Array<RecapitoDto>> {
        return this.apiRecapitoRecapitoCorpoListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<RecapitoDto>>): Array<RecapitoDto> => r.body),
        );
    }
}
