/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiModuloAggiuntivoCorpoWithCountListGet } from '../fn/modulo-aggiuntivo/api-modulo-aggiuntivo-corpo-with-count-list-get';
import { ApiModuloAggiuntivoCorpoWithCountListGet$Params } from '../fn/modulo-aggiuntivo/api-modulo-aggiuntivo-corpo-with-count-list-get';
import { apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet } from '../fn/modulo-aggiuntivo/api-modulo-aggiuntivo-modulo-aggiuntivo-list-by-corpo-get';
import { ApiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Params } from '../fn/modulo-aggiuntivo/api-modulo-aggiuntivo-modulo-aggiuntivo-list-by-corpo-get';
import { apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost } from '../fn/modulo-aggiuntivo/api-modulo-aggiuntivo-update-modulo-aggiuntivo-list-for-corpo-post';
import { ApiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost$Params } from '../fn/modulo-aggiuntivo/api-modulo-aggiuntivo-update-modulo-aggiuntivo-list-for-corpo-post';
import { ModuloAggiuntivoCorpoCountDto } from '../models/modulo-aggiuntivo-corpo-count-dto';

@Injectable({ providedIn: 'root' })
export class ModuloAggiuntivoService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiModuloAggiuntivoCorpoWithCountListGet()` */
    static readonly ApiModuloAggiuntivoCorpoWithCountListGetPath = '/api/modulo-aggiuntivo/corpo-with-count-list';

    /**
     * carica la lista dei corpi che hanno dei moduli aggiuntivi abilitati con il contatore dei moduli attivi.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiModuloAggiuntivoCorpoWithCountListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiModuloAggiuntivoCorpoWithCountListGet$Response(
        params?: ApiModuloAggiuntivoCorpoWithCountListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<ModuloAggiuntivoCorpoCountDto>>> {
        return apiModuloAggiuntivoCorpoWithCountListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * carica la lista dei corpi che hanno dei moduli aggiuntivi abilitati con il contatore dei moduli attivi.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiModuloAggiuntivoCorpoWithCountListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiModuloAggiuntivoCorpoWithCountListGet(
        params?: ApiModuloAggiuntivoCorpoWithCountListGet$Params,
        context?: HttpContext,
    ): Observable<Array<ModuloAggiuntivoCorpoCountDto>> {
        return this.apiModuloAggiuntivoCorpoWithCountListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<ModuloAggiuntivoCorpoCountDto>>): Array<ModuloAggiuntivoCorpoCountDto> => r.body),
        );
    }

    /** Path part for operation `apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet()` */
    static readonly ApiModuloAggiuntivoModuloAggiuntivoListByCorpoGetPath = '/api/modulo-aggiuntivo/modulo-aggiuntivo-list-by-corpo';

    /**
     * Carica la lista dei moduli attivi per un corpo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Response(
        params: ApiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<number>>> {
        return apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista dei moduli attivi per un corpo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet(
        params: ApiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Params,
        context?: HttpContext,
    ): Observable<Array<number>> {
        return this.apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body),
        );
    }

    /** Path part for operation `apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost()` */
    static readonly ApiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPostPath =
        '/api/modulo-aggiuntivo/update-modulo-aggiuntivo-list-for-corpo';

    /**
     * Aggiorna la lista dei moduli aggiuntivi abilitati per il corpo.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost$Response(
        params: ApiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<void>> {
        return apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Aggiorna la lista dei moduli aggiuntivi abilitati per il corpo.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost(
        params: ApiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost$Params,
        context?: HttpContext,
    ): Observable<void> {
        return this.apiModuloAggiuntivoUpdateModuloAggiuntivoListForCorpoPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<void>): void => r.body),
        );
    }
}
