/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CorpoListItemDto } from '../../models/corpo-list-item-dto';

export interface ApiCaricamentoMultimedialeCorpoConfiguratoListGet$Params {}

export function apiCaricamentoMultimedialeCorpoConfiguratoListGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiCaricamentoMultimedialeCorpoConfiguratoListGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<Array<CorpoListItemDto>>> {
    const rb = new RequestBuilder(rootUrl, apiCaricamentoMultimedialeCorpoConfiguratoListGet.PATH, 'get');
    if (params) {
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<CorpoListItemDto>>;
        }),
    );
}

apiCaricamentoMultimedialeCorpoConfiguratoListGet.PATH = '/api/caricamento-multimediale/corpo-configurato-list';
