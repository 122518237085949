<header class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark shadow">
    <div class="container-fluid">
        <div class="d-flex flex-wrap align-items-center">
            <button
                class="navbar-toggler mr-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand fw-bold" href="/">FirePi</a>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    @for (item of items; track $index) {
                        <li class="nav-items {{ !item.items ? '' : 'dropdown' }}  ">
                            @if (item.visible && !item.items) {
                                <a
                                    class="nav-link {{ this.isCurrentPage(item.routerLink[0]) ? 'active' : '' }}"
                                    aria-current="page"
                                    href="{{ item.routerLink[0] }}"
                                    >{{ item.label }}</a
                                >
                            }
                            @if (item.visible && item.items) {
                                <a
                                    class="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdownMenu"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{ item.label }}
                                </a>
                            }
                            @if (item.visible && item.items) {
                                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdownMenuLink">
                                    @for (subItem of item.items; track $index) {
                                        @if (subItem.visible) {
                                            <li>
                                                <a class="dropdown-item" href="{{ subItem.routerLink[0] }}">{{ subItem.label }}</a>
                                            </li>
                                        }
                                    }
                                </ul>
                            }
                        </li>
                    }
                </ul>
            </div>
        </div>

        <div class="dropdown text-end mt-1 mb-auto">
            <a
                href="#"
                class="d-block link-dark text-decoration-none dropdown-toggle text-white"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="text-avatar mr-2">{{ user | userInitials }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-small" aria-labelledby="dropdownUser1">
                <li class="dropdown-item cursor-pointer">
                    <span>{{ userName }}</span
                    ><br />
                    <samp class="text-secondary small">{{ this.userType }}</samp>
                </li>

                <li><hr class="dropdown-divider" /></li>

                @if (gradoList.length > 1) {
                    <div>
                        <li class="dropdown-item fw-bold">Grado selezionato</li>
                        @for (grado of gradoList; let gradoIndex = $index; track gradoIndex) {
                            <li [class.no-border]="gradoIndex + 1 !== gradoList.length" (click)="changeGrado(grado)" class="dropdown-item">
                                <span><i [class.transparent]="!grado.selected" class="pi pi-check"></i></span>
                                <span class="{{ !grado.selected ? 'text-success' : '' }}">{{ grado.descrizioneGrado }}</span>
                            </li>
                        }

                        <li><hr class="dropdown-divider" /></li>
                    </div>
                }

                @if (canSeeGestioneUtenti) {
                    <li class="dropdown-item cursor-pointer" [routerLink]="'/gestione-utenti/'">
                        <span>Gestione utenti</span>
                    </li>
                }
                @if (canSeeGestioneRuoli) {
                    <li class="dropdown-item cursor-pointer" [routerLink]="'/gestione-ruoli/'">
                        <span>Gestione ruoli</span>
                    </li>
                }
                @if (canSeeGestioneUtenti || canSeeGestioneRuoli) {
                    <li><hr class="dropdown-divider" /></li>
                }

                <li class="dropdown-item text-secondary cursor-pointer" [routerLink]="'/changelog'">Changelog</li>

                <li><hr class="dropdown-divider" /></li>

                <li class="dropdown-item text-danger cursor-pointer" (click)="logout()">Disconnetti</li>
            </ul>
        </div>
    </div>
</header>
