/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiOtpGeneraOtpGet } from '../fn/otp/api-otp-genera-otp-get';
import { ApiOtpGeneraOtpGet$Params } from '../fn/otp/api-otp-genera-otp-get';
import { apiOtpRichiediOtpGet } from '../fn/otp/api-otp-richiedi-otp-get';
import { ApiOtpRichiediOtpGet$Params } from '../fn/otp/api-otp-richiedi-otp-get';
import { apiOtpVerificaOtpGet } from '../fn/otp/api-otp-verifica-otp-get';
import { ApiOtpVerificaOtpGet$Params } from '../fn/otp/api-otp-verifica-otp-get';
import { NuovoOtpDto } from '../models/nuovo-otp-dto';

@Injectable({ providedIn: 'root' })
export class OtpService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiOtpRichiediOtpGet()` */
    static readonly ApiOtpRichiediOtpGetPath = '/api/otp/richiedi-otp';

    /**
     * Se l'utente richiesto è registrato al portale e ha almeno un dispositivo mobile attivo,
     * 				invia all'app una richiesta di generazione OTP.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOtpRichiediOtpGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOtpRichiediOtpGet$Response(params: ApiOtpRichiediOtpGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
        return apiOtpRichiediOtpGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Se l'utente richiesto è registrato al portale e ha almeno un dispositivo mobile attivo,
     * 				invia all'app una richiesta di generazione OTP.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOtpRichiediOtpGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOtpRichiediOtpGet(params: ApiOtpRichiediOtpGet$Params, context?: HttpContext): Observable<void> {
        return this.apiOtpRichiediOtpGet$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
    }

    /** Path part for operation `apiOtpGeneraOtpGet()` */
    static readonly ApiOtpGeneraOtpGetPath = '/api/otp/genera-otp';

    /**
     * Genera un codice OTP per l'utente che sta effettuando la chiamata. Se non è presente nessuna richiesta OTP ritorna errore.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOtpGeneraOtpGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOtpGeneraOtpGet$Response(params?: ApiOtpGeneraOtpGet$Params, context?: HttpContext): Observable<StrictHttpResponse<NuovoOtpDto>> {
        return apiOtpGeneraOtpGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Genera un codice OTP per l'utente che sta effettuando la chiamata. Se non è presente nessuna richiesta OTP ritorna errore.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOtpGeneraOtpGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOtpGeneraOtpGet(params?: ApiOtpGeneraOtpGet$Params, context?: HttpContext): Observable<NuovoOtpDto> {
        return this.apiOtpGeneraOtpGet$Response(params, context).pipe(map((r: StrictHttpResponse<NuovoOtpDto>): NuovoOtpDto => r.body));
    }

    /** Path part for operation `apiOtpVerificaOtpGet()` */
    static readonly ApiOtpVerificaOtpGetPath = '/api/otp/verifica-otp';

    /**
     * Genera un codice OTP per l'utente che sta effettuando la chiamata. Se non è presente nessuna richiesta OTP ritoena errore.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiOtpVerificaOtpGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOtpVerificaOtpGet$Response(params: ApiOtpVerificaOtpGet$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
        return apiOtpVerificaOtpGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Genera un codice OTP per l'utente che sta effettuando la chiamata. Se non è presente nessuna richiesta OTP ritoena errore.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiOtpVerificaOtpGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiOtpVerificaOtpGet(params: ApiOtpVerificaOtpGet$Params, context?: HttpContext): Observable<boolean> {
        return this.apiOtpVerificaOtpGet$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }
}
