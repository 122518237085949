/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LogMobileAppDto } from '../../models/log-mobile-app-dto';
import { ReturnLogMobileAppDto } from '../../models/return-log-mobile-app-dto';

export interface ApiLogMobileAppPost$Params {
    /**
     * application/json
     */
    body: LogMobileAppDto;
}

export function apiLogMobileAppPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiLogMobileAppPost$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<ReturnLogMobileAppDto>> {
    const rb = new RequestBuilder(rootUrl, apiLogMobileAppPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'Log to add');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<ReturnLogMobileAppDto>;
        }),
    );
}

apiLogMobileAppPost.PATH = '/api/log/mobile-app';
