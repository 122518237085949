/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Params {
    /**
     * Corpo per cui caricare la lista dei moduli aggiuntivi
     */
    corpoId: number;
}

export function apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiModuloAggiuntivoModuloAggiuntivoListByCorpoGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<Array<number>>> {
    const rb = new RequestBuilder(rootUrl, apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet.PATH, 'get');
    if (params) {
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<number>>;
        }),
    );
}

apiModuloAggiuntivoModuloAggiuntivoListByCorpoGet.PATH = '/api/modulo-aggiuntivo/modulo-aggiuntivo-list-by-corpo';
