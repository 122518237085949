/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiDispositivoMobileGet$Params {
    /**
     * Skip the first n elements
     */
    skip?: number;

    /**
     * Take the first n elements
     */
    take?: number;
}

export function apiDispositivoMobileGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiDispositivoMobileGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiDispositivoMobileGet.PATH, 'get');
    if (params) {
        rb.query('skip', params.skip, {});
        rb.query('take', params.take, {});
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
    );
}

apiDispositivoMobileGet.PATH = '/api/dispositivo-mobile';
