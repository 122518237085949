/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiTestPushNotificationTestPost } from '../fn/test/api-test-push-notification-test-post';
import { ApiTestPushNotificationTestPost$Params } from '../fn/test/api-test-push-notification-test-post';
import { apiTestSelettivaTestPost } from '../fn/test/api-test-selettiva-test-post';
import { ApiTestSelettivaTestPost$Params } from '../fn/test/api-test-selettiva-test-post';
import { apiTestUploadGdriveFilePost } from '../fn/test/api-test-upload-gdrive-file-post';
import { ApiTestUploadGdriveFilePost$Params } from '../fn/test/api-test-upload-gdrive-file-post';

@Injectable({ providedIn: 'root' })
export class TestService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiTestPushNotificationTestPost()` */
    static readonly ApiTestPushNotificationTestPostPath = '/api/test/push-notification-test';

    /**
     * Invia una push notification di notifica di prova (Non crea nessuna notifica nel database).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiTestPushNotificationTestPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTestPushNotificationTestPost$Response(
        params: ApiTestPushNotificationTestPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiTestPushNotificationTestPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Invia una push notification di notifica di prova (Non crea nessuna notifica nel database).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiTestPushNotificationTestPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTestPushNotificationTestPost(params: ApiTestPushNotificationTestPost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiTestPushNotificationTestPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }

    /** Path part for operation `apiTestSelettivaTestPost()` */
    static readonly ApiTestSelettivaTestPostPath = '/api/test/selettiva-test';

    /**
     * Invia una push notification di selettiva di prova (Non crea nessuna selettiva nel database).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiTestSelettivaTestPost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTestSelettivaTestPost$Response(
        params: ApiTestSelettivaTestPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiTestSelettivaTestPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Invia una push notification di selettiva di prova (Non crea nessuna selettiva nel database).
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiTestSelettivaTestPost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTestSelettivaTestPost(params: ApiTestSelettivaTestPost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiTestSelettivaTestPost$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }

    /** Path part for operation `apiTestUploadGdriveFilePost()` */
    static readonly ApiTestUploadGdriveFilePostPath = '/api/test/upload-gdrive-file';

    /**
     * Test per il caricamento di un file su GDrive.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiTestUploadGdriveFilePost()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTestUploadGdriveFilePost$Response(
        params?: ApiTestUploadGdriveFilePost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiTestUploadGdriveFilePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Test per il caricamento di un file su GDrive.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiTestUploadGdriveFilePost$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiTestUploadGdriveFilePost(params?: ApiTestUploadGdriveFilePost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiTestUploadGdriveFilePost$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }
}
