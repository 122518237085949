/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiNumeroUtileDeleteNumeroUtileByIdDelete } from '../fn/numero-utile/api-numero-utile-delete-numero-utile-by-id-delete';
import { ApiNumeroUtileDeleteNumeroUtileByIdDelete$Params } from '../fn/numero-utile/api-numero-utile-delete-numero-utile-by-id-delete';
import { apiNumeroUtileNumeroUtileByIdGet } from '../fn/numero-utile/api-numero-utile-numero-utile-by-id-get';
import { ApiNumeroUtileNumeroUtileByIdGet$Params } from '../fn/numero-utile/api-numero-utile-numero-utile-by-id-get';
import { apiNumeroUtileNumeroUtileInsertPost } from '../fn/numero-utile/api-numero-utile-numero-utile-insert-post';
import { ApiNumeroUtileNumeroUtileInsertPost$Params } from '../fn/numero-utile/api-numero-utile-numero-utile-insert-post';
import { apiNumeroUtileNumeroUtileListGet } from '../fn/numero-utile/api-numero-utile-numero-utile-list-get';
import { ApiNumeroUtileNumeroUtileListGet$Params } from '../fn/numero-utile/api-numero-utile-numero-utile-list-get';
import { NumeroUtileDto } from '../models/numero-utile-dto';

@Injectable({ providedIn: 'root' })
export class NumeroUtileService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiNumeroUtileNumeroUtileListGet()` */
    static readonly ApiNumeroUtileNumeroUtileListGetPath = '/api/numero-utile/numero-utile-list';

    /**
     * Ritorna la lista dei numeri utili per il corpo dell'utente.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNumeroUtileNumeroUtileListGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNumeroUtileNumeroUtileListGet$Response(
        params?: ApiNumeroUtileNumeroUtileListGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<NumeroUtileDto>>> {
        return apiNumeroUtileNumeroUtileListGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Ritorna la lista dei numeri utili per il corpo dell'utente.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNumeroUtileNumeroUtileListGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNumeroUtileNumeroUtileListGet(
        params?: ApiNumeroUtileNumeroUtileListGet$Params,
        context?: HttpContext,
    ): Observable<Array<NumeroUtileDto>> {
        return this.apiNumeroUtileNumeroUtileListGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<NumeroUtileDto>>): Array<NumeroUtileDto> => r.body),
        );
    }

    /** Path part for operation `apiNumeroUtileNumeroUtileByIdGet()` */
    static readonly ApiNumeroUtileNumeroUtileByIdGetPath = '/api/numero-utile/numero-utile-by-id';

    /**
     * Ritorna un numero utile in base al suo id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNumeroUtileNumeroUtileByIdGet()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNumeroUtileNumeroUtileByIdGet$Response(
        params: ApiNumeroUtileNumeroUtileByIdGet$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<NumeroUtileDto>> {
        return apiNumeroUtileNumeroUtileByIdGet(this.http, this.rootUrl, params, context);
    }

    /**
     * Ritorna un numero utile in base al suo id.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNumeroUtileNumeroUtileByIdGet$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNumeroUtileNumeroUtileByIdGet(params: ApiNumeroUtileNumeroUtileByIdGet$Params, context?: HttpContext): Observable<NumeroUtileDto> {
        return this.apiNumeroUtileNumeroUtileByIdGet$Response(params, context).pipe(
            map((r: StrictHttpResponse<NumeroUtileDto>): NumeroUtileDto => r.body),
        );
    }

    /** Path part for operation `apiNumeroUtileNumeroUtileInsertPost()` */
    static readonly ApiNumeroUtileNumeroUtileInsertPostPath = '/api/numero-utile/numero-utile-insert';

    /**
     * Inserisce o aggiorna un numero utile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNumeroUtileNumeroUtileInsertPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiNumeroUtileNumeroUtileInsertPost$Response(
        params: ApiNumeroUtileNumeroUtileInsertPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<boolean>>> {
        return apiNumeroUtileNumeroUtileInsertPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Inserisce o aggiorna un numero utile.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNumeroUtileNumeroUtileInsertPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiNumeroUtileNumeroUtileInsertPost(
        params: ApiNumeroUtileNumeroUtileInsertPost$Params,
        context?: HttpContext,
    ): Observable<Array<boolean>> {
        return this.apiNumeroUtileNumeroUtileInsertPost$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<boolean>>): Array<boolean> => r.body),
        );
    }

    /** Path part for operation `apiNumeroUtileDeleteNumeroUtileByIdDelete()` */
    static readonly ApiNumeroUtileDeleteNumeroUtileByIdDeletePath = '/api/numero-utile/delete-numero-utile-by-id';

    /**
     * Elimina un numero utile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiNumeroUtileDeleteNumeroUtileByIdDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNumeroUtileDeleteNumeroUtileByIdDelete$Response(
        params: ApiNumeroUtileDeleteNumeroUtileByIdDelete$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiNumeroUtileDeleteNumeroUtileByIdDelete(this.http, this.rootUrl, params, context);
    }

    /**
     * Elimina un numero utile.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiNumeroUtileDeleteNumeroUtileByIdDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiNumeroUtileDeleteNumeroUtileByIdDelete(
        params: ApiNumeroUtileDeleteNumeroUtileByIdDelete$Params,
        context?: HttpContext,
    ): Observable<boolean> {
        return this.apiNumeroUtileDeleteNumeroUtileByIdDelete$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }
}
