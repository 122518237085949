/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiOtpRichiediOtpGet$Params {
    /**
     * utente a cui si vuole inviare la notifica OTP
     */
    nomeUtente: string;

    /**
     * secondi di validità del token OTP, se non viene specificato prende il valore di default (30 secondi)
     */
    secondiValidita?: string;
}

export function apiOtpRichiediOtpGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiOtpRichiediOtpGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(rootUrl, apiOtpRichiediOtpGet.PATH, 'get');
    if (params) {
        rb.query('nomeUtente', params.nomeUtente, {});
        rb.query('secondiValidita', params.secondiValidita, {});
    }

    return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
    );
}

apiOtpRichiediOtpGet.PATH = '/api/otp/richiedi-otp';
