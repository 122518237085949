/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CaricamentoMultimedialeUpdateGDriveParentIdFolderDto } from '../../models/caricamento-multimediale-update-g-drive-parent-id-folder-dto';

export interface ApiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Params {
    /**
     * application/json
     */
    body: CaricamentoMultimedialeUpdateGDriveParentIdFolderDto;
}

export function apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost(
    http: HttpClient,
    rootUrl: string,
    params: ApiCaricamentoMultimedialeUpdateGdriveParentidFolderPost$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(rootUrl, apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost.PATH, 'post');
    if (params) {
        rb.body(params.body, 'application/json');
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return (r as HttpResponse<any>).clone({
                body: String((r as HttpResponse<any>).body) === 'true',
            }) as StrictHttpResponse<boolean>;
        }),
    );
}

apiCaricamentoMultimedialeUpdateGdriveParentidFolderPost.PATH = '/api/caricamento-multimediale/update-gdrive-parentid-folder';
