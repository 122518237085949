export const environment = {
    production: true,
    enableDevModule: false,
    firebase: {
        apiKey: 'AIzaSyBqH1f9qcmWoXYB4sl263NI-jMyCq7POxs',
        authDomain: 'firepi-c19dc.firebaseapp.com',
        projectId: 'firepi-c19dc',
        storageBucket: 'firepi-c19dc.appspot.com',
        messagingSenderId: '573883249847',
        appId: '1:573883249847:web:aafaa6fff0864387700932',
        vapidKey: 'BDOAEzjS6G1AtgX7V6LtrFLLrc2h2mQaM2fTNMAHavgmgPQkM384fU2jjMLMDXiNZW5J7WMB_QqYnaIf9nY8QGc',
    },
    gDriveAuth: {
        clientId: '277386909615-qrcpfmqis22shso15gu8qptonkmucdoi.apps.googleusercontent.com',
        apiKey: 'AIzaSyBzZwKK26ri_eMN4JWbrhlPsD1SCiIXXTQ',
        scopes: 'https://www.googleapis.com/auth/drive.file',
    },
};
