/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SelettivaListItemDto } from '../../models/selettiva-list-item-dto';

export interface ApiSelettivaSelettivaListGet$Params {
    /**
     * numero di record da caricare
     */
    take?: number;

    /**
     * numero di record da ignorare nella paginazione
     */
    skip?: number;

    /**
     * se true, carica il numero di partecipanti in arrivo
     */
    populateAnswers?: boolean;

    /**
     * se true, carica il numero di notifiche inviate
     */
    populateVigiliAllertati?: boolean;
}

export function apiSelettivaSelettivaListGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiSelettivaSelettivaListGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<Array<SelettivaListItemDto>>> {
    const rb = new RequestBuilder(rootUrl, apiSelettivaSelettivaListGet.PATH, 'get');
    if (params) {
        rb.query('take', params.take, {});
        rb.query('skip', params.skip, {});
        rb.query('populateAnswers', params.populateAnswers, {});
        rb.query('populateVigiliAllertati', params.populateVigiliAllertati, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<SelettivaListItemDto>>;
        }),
    );
}

apiSelettivaSelettivaListGet.PATH = '/api/selettiva/selettiva-list';
