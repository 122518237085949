/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RuoloPermessoDto } from '../../models/ruolo-permesso-dto';

export interface ApiAuthRuoloPermessoListGet$Params {
    /**
     * Id del ruolo per cui si vogliono caricare i permessi
     */
    ruoloId: number;

    /**
     * Id del corpo per cui caricare i permessi associati al ruolo
     */
    corpoId?: number;
}

export function apiAuthRuoloPermessoListGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiAuthRuoloPermessoListGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<Array<RuoloPermessoDto>>> {
    const rb = new RequestBuilder(rootUrl, apiAuthRuoloPermessoListGet.PATH, 'get');
    if (params) {
        rb.query('ruoloId', params.ruoloId, {});
        rb.query('corpoId', params.corpoId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<Array<RuoloPermessoDto>>;
        }),
    );
}

apiAuthRuoloPermessoListGet.PATH = '/api/auth/ruolo-permesso-list';
