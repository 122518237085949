/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiMarcatoreIdranteListByPosizionePost } from '../fn/marcatore/api-marcatore-idrante-list-by-posizione-post';
import { ApiMarcatoreIdranteListByPosizionePost$Params } from '../fn/marcatore/api-marcatore-idrante-list-by-posizione-post';
import { apiMarcatoreMarcatoreDeleteDelete } from '../fn/marcatore/api-marcatore-marcatore-delete-delete';
import { ApiMarcatoreMarcatoreDeleteDelete$Params } from '../fn/marcatore/api-marcatore-marcatore-delete-delete';
import { apiMarcatoreMarcatoreEditPost } from '../fn/marcatore/api-marcatore-marcatore-edit-post';
import { ApiMarcatoreMarcatoreEditPost$Params } from '../fn/marcatore/api-marcatore-marcatore-edit-post';
import { apiMarcatoreMarcatoreInsertPost } from '../fn/marcatore/api-marcatore-marcatore-insert-post';
import { ApiMarcatoreMarcatoreInsertPost$Params } from '../fn/marcatore/api-marcatore-marcatore-insert-post';
import { apiMarcatoreMarcatoreListByPosizionePost } from '../fn/marcatore/api-marcatore-marcatore-list-by-posizione-post';
import { ApiMarcatoreMarcatoreListByPosizionePost$Params } from '../fn/marcatore/api-marcatore-marcatore-list-by-posizione-post';
import { apiMarcatoreMarcatoreListPost } from '../fn/marcatore/api-marcatore-marcatore-list-post';
import { ApiMarcatoreMarcatoreListPost$Params } from '../fn/marcatore/api-marcatore-marcatore-list-post';
import { MarcatoreDto } from '../models/marcatore-dto';

@Injectable({ providedIn: 'root' })
export class MarcatoreService extends BaseService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /** Path part for operation `apiMarcatoreMarcatoreListPost()` */
    static readonly ApiMarcatoreMarcatoreListPostPath = '/api/marcatore/marcatore-list';

    /**
     * Carica la lista di marcatori appartenenti al corpo del richiedente, in base ai tipi richiesti.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiMarcatoreMarcatoreListPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreListPost$Response(
        params: ApiMarcatoreMarcatoreListPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<number>> {
        return apiMarcatoreMarcatoreListPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica la lista di marcatori appartenenti al corpo del richiedente, in base ai tipi richiesti.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiMarcatoreMarcatoreListPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreListPost(params: ApiMarcatoreMarcatoreListPost$Params, context?: HttpContext): Observable<number> {
        return this.apiMarcatoreMarcatoreListPost$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
    }

    /** Path part for operation `apiMarcatoreMarcatoreListByPosizionePost()` */
    static readonly ApiMarcatoreMarcatoreListByPosizionePostPath = '/api/marcatore/marcatore-list-by-posizione';

    /**
     * Carica i marcatori del tipo indicato contenuti all'interno di un area rettangolare.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiMarcatoreMarcatoreListByPosizionePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreListByPosizionePost$Response(
        params: ApiMarcatoreMarcatoreListByPosizionePost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<MarcatoreDto>>> {
        return apiMarcatoreMarcatoreListByPosizionePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica i marcatori del tipo indicato contenuti all'interno di un area rettangolare.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiMarcatoreMarcatoreListByPosizionePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreListByPosizionePost(
        params: ApiMarcatoreMarcatoreListByPosizionePost$Params,
        context?: HttpContext,
    ): Observable<Array<MarcatoreDto>> {
        return this.apiMarcatoreMarcatoreListByPosizionePost$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<MarcatoreDto>>): Array<MarcatoreDto> => r.body),
        );
    }

    /** Path part for operation `apiMarcatoreIdranteListByPosizionePost()` */
    static readonly ApiMarcatoreIdranteListByPosizionePostPath = '/api/marcatore/idrante-list-by-posizione';

    /**
     * Carica tutti gli idranti contenuti all'interno di un area rettangolare.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiMarcatoreIdranteListByPosizionePost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreIdranteListByPosizionePost$Response(
        params: ApiMarcatoreIdranteListByPosizionePost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<Array<MarcatoreDto>>> {
        return apiMarcatoreIdranteListByPosizionePost(this.http, this.rootUrl, params, context);
    }

    /**
     * Carica tutti gli idranti contenuti all'interno di un area rettangolare.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiMarcatoreIdranteListByPosizionePost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreIdranteListByPosizionePost(
        params: ApiMarcatoreIdranteListByPosizionePost$Params,
        context?: HttpContext,
    ): Observable<Array<MarcatoreDto>> {
        return this.apiMarcatoreIdranteListByPosizionePost$Response(params, context).pipe(
            map((r: StrictHttpResponse<Array<MarcatoreDto>>): Array<MarcatoreDto> => r.body),
        );
    }

    /** Path part for operation `apiMarcatoreMarcatoreInsertPost()` */
    static readonly ApiMarcatoreMarcatoreInsertPostPath = '/api/marcatore/marcatore-insert';

    /**
     * Inserisce un nuovo marcatore associato al corpo dell'utente che sta facendo la richiesta, e ritorna l'id del marcatore creato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiMarcatoreMarcatoreInsertPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreInsertPost$Response(
        params: ApiMarcatoreMarcatoreInsertPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<number>> {
        return apiMarcatoreMarcatoreInsertPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Inserisce un nuovo marcatore associato al corpo dell'utente che sta facendo la richiesta, e ritorna l'id del marcatore creato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiMarcatoreMarcatoreInsertPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreInsertPost(params: ApiMarcatoreMarcatoreInsertPost$Params, context?: HttpContext): Observable<number> {
        return this.apiMarcatoreMarcatoreInsertPost$Response(params, context).pipe(map((r: StrictHttpResponse<number>): number => r.body));
    }

    /** Path part for operation `apiMarcatoreMarcatoreEditPost()` */
    static readonly ApiMarcatoreMarcatoreEditPostPath = '/api/marcatore/marcatore-edit';

    /**
     * Modifica un marcatore associato al corpo dell'utente che sta facendo la richiesta, e ritorna un booleano che indica se è stato aggiornato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiMarcatoreMarcatoreEditPost()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreEditPost$Response(
        params: ApiMarcatoreMarcatoreEditPost$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiMarcatoreMarcatoreEditPost(this.http, this.rootUrl, params, context);
    }

    /**
     * Modifica un marcatore associato al corpo dell'utente che sta facendo la richiesta, e ritorna un booleano che indica se è stato aggiornato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiMarcatoreMarcatoreEditPost$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    apiMarcatoreMarcatoreEditPost(params: ApiMarcatoreMarcatoreEditPost$Params, context?: HttpContext): Observable<boolean> {
        return this.apiMarcatoreMarcatoreEditPost$Response(params, context).pipe(map((r: StrictHttpResponse<boolean>): boolean => r.body));
    }

    /** Path part for operation `apiMarcatoreMarcatoreDeleteDelete()` */
    static readonly ApiMarcatoreMarcatoreDeleteDeletePath = '/api/marcatore/marcatore-delete';

    /**
     * Elimina un marcatore associato al corpo dell'utente che sta facendo la richiesta, e ritorna un booleano che indica se è stato eliminato.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiMarcatoreMarcatoreDeleteDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiMarcatoreMarcatoreDeleteDelete$Response(
        params: ApiMarcatoreMarcatoreDeleteDelete$Params,
        context?: HttpContext,
    ): Observable<StrictHttpResponse<boolean>> {
        return apiMarcatoreMarcatoreDeleteDelete(this.http, this.rootUrl, params, context);
    }

    /**
     * Elimina un marcatore associato al corpo dell'utente che sta facendo la richiesta, e ritorna un booleano che indica se è stato eliminato.
     *
     *
     *
     * This method provides access only to the response body.
     * To access the full response (for headers, for example), `apiMarcatoreMarcatoreDeleteDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiMarcatoreMarcatoreDeleteDelete(params: ApiMarcatoreMarcatoreDeleteDelete$Params, context?: HttpContext): Observable<boolean> {
        return this.apiMarcatoreMarcatoreDeleteDelete$Response(params, context).pipe(
            map((r: StrictHttpResponse<boolean>): boolean => r.body),
        );
    }
}
