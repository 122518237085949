/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DefaultSingleRecordReturnDto } from '../../models/default-single-record-return-dto';

export interface ApiSessionsCountGet$Params {
    /**
     * Query helper
     */
    query?: string;
}

export function apiSessionsCountGet(
    http: HttpClient,
    rootUrl: string,
    params?: ApiSessionsCountGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<DefaultSingleRecordReturnDto>> {
    const rb = new RequestBuilder(rootUrl, apiSessionsCountGet.PATH, 'get');
    if (params) {
        rb.query('query', params.query, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<DefaultSingleRecordReturnDto>;
        }),
    );
}

apiSessionsCountGet.PATH = '/api/sessions/count';
