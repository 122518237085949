/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface ApiSelettivaSelettivaAudioBySelettivaIdGet$Params {
    /**
     * id della selettiva da caricare
     */
    selettivaId: number;
}

export function apiSelettivaSelettivaAudioBySelettivaIdGet(
    http: HttpClient,
    rootUrl: string,
    params: ApiSelettivaSelettivaAudioBySelettivaIdGet$Params,
    context?: HttpContext,
): Observable<StrictHttpResponse<{}>> {
    const rb = new RequestBuilder(rootUrl, apiSelettivaSelettivaAudioBySelettivaIdGet.PATH, 'get');
    if (params) {
        rb.query('selettivaId', params.selettivaId, {});
    }

    return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
            return r as StrictHttpResponse<{}>;
        }),
    );
}

apiSelettivaSelettivaAudioBySelettivaIdGet.PATH = '/api/selettiva/selettiva-audio-by-selettiva-id';
